import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

export default function ModalTutorial({
  open,
  closeModal,
  title,
  textButtonOk,
  checked,
  onChangeChecked,
}) {
  const { t } = useTranslate();

  const handleOk = () => {
    closeModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleOk}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div"></DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                color="shief"
                checked={checked}
                onChange={onChangeChecked}
              />
            }
            label={t("DONT_SHOW_TUTORIAL")}
            sx={{ marginRight: 0 }}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOk} autoFocus color="modalButton">
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
