import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@mui/material";

import WhatshotIcon from "@mui/icons-material/Whatshot";

import { Toggle } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

export default function ModalUseHot({
  onSubmit,
  open,
  closeModal,
  title = "",
  textButtonOk,
  textButtonCancel,
  additionalFunc,
  isUseKey = false,
  addYourself,
  handleSwitch,
}) {
  const [code, setCode] = useState("");
  const [validationErrors, setValidationErrors] = useState(false);
  const { t } = useTranslate();

  const handleCancel = () => {
    closeModal();
    additionalFunc && additionalFunc();
  };

  const handleOk = () => {
    if (!code) {
      setValidationErrors(true);
      return;
    }

    setValidationErrors(false);

    onSubmit(code);
    closeModal();
  };

  const changeHandler = (e) => {
    setCode(e.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          {isUseKey && (
            <Toggle
              label={t("ADD_EVENT_YOURSELF")}
              checked={addYourself}
              handleSwitch={handleSwitch}
              sx={{ marginBottom: "12px" }}
            />
          )}

          <TextField
            fullWidth
            id="standard-basic"
            variant="standard"
            type="text"
            name="code"
            onChange={changeHandler}
            value={code}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatshotIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 5,
            }}
            required
            error={validationErrors}
            helperText={validationErrors && t("ERROR_KEY_REQ")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="modalButton">
            {textButtonCancel || t("CANCEL")}
          </Button>
          <Button onClick={handleOk} autoFocus color="modalButton">
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
