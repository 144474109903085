import { Stack, Box, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { useTranslate, useMapAddress } from "hooks";

import { startGeolocation } from "config/configs";

const MapAddressSearch = ({ handleClose, address, setAddress }) => {
  const { t } = useTranslate();

  const { changeAdress, error, markerPosition, LocationMarker } = useMapAddress(
    null,
    setAddress
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          label={t("ADDRESS")}
          variant="standard"
          type="text"
          name="address"
          onChange={changeAdress}
          value={address}
          error={error}
          helperText={error && t("ERROR_ADDRESS")}
          sx={{
            marginBottom: "16px",
          }}
        />

        <MapContainer
          center={markerPosition || startGeolocation}
          zoom={13}
          style={{ height: "200px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker />
        </MapContainer>
      </Box>

      <IconButton
        onClick={handleClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default MapAddressSearch;
