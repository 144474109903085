import { useState } from "react";

import { isMobile } from "react-device-detect";

import {
  Button,
  Chip,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import InfoIcon from "@mui/icons-material/Info";
import DirectionsIcon from "@mui/icons-material/Directions";

import cn from "classnames";

import {
  Modal,
  ModalLink,
  MapAddressShow,
  OwnerBord,
  ModalParticipantsManager,
  QueueCounter,
} from "components/Elements";

import {
  parsStatus,
  dateDDMMYYYY,
  dateHHmm,
  diffInDays,
  diffInMinutes,
} from "utils/common";

import { useTranslate, useOwnerBord } from "hooks";

import classes from "./TableRow.module.scss";

const TableRow = ({
  eventDate,
  name,
  message,
  status,
  _id,
  handleStop,
  handleDel,
  createDate,
  eventlink,
  owner,
  userId,
  getParticipants,
  hotCode,
  setHotCode,
  generateHotKey,
  timeHotCode,
  timerId,
  tableScroll,
  isExpandedProp,
  address,
  isLiveQueue = false,
  isLoadingInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedProp ?? false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalStop, setIsOpenModalStop] = useState(false);
  const [isOpenModalLink, setIsOpenModalLink] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(158);
  const [userNumber, setUserNumber] = useState(210);

  false && console.log(setCurrentNumber, setUserNumber);

  const { t } = useTranslate();

  const {
    dataParticipants,
    fullNameParticipants,
    invite,
    finish,
    isOpenModalInviteParticipants,
    closeModalInviteParticipants,
    isOpenModalFinishParticipants,
    closeModalFinishParticipants,
    inviteSubmit,
    finishSubmit,
  } = useOwnerBord();

  // const linkInvitation = `${process.env.REACT_APP_URL}/useLinkevent/${eventlink}`;
  const linkInvitation = `/events?useKey=${eventlink}`;

  const handleToggleExpand = () => {
    if (isExpandedProp) {
      return;
    }

    setIsExpanded(!isExpanded);
    if (tableScroll && !isExpanded) {
      tableScroll();
    }
  };

  const closeModalLink = () => {
    hotCode && setHotCode(null);
    timerId && clearTimeout(timerId);
    setIsOpenModalLink(false);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const closeModalStop = () => {
    setIsOpenModalStop(false);
  };

  const handleOpenAddressMobile = (addressValue) => {
    if (isMobile) {
      const url = `geo:0,0?q=${encodeURIComponent(addressValue)}`;
      window.location.href = url;
    }
  };

  const isOwnerLive = isLiveQueue && userId === owner;
  const isNotOwnerLive = isLiveQueue && userId !== owner;

  const daysDiff = diffInDays(eventDate);
  const labelChipParse = () => {
    if (daysDiff > 1) {
      return `${daysDiff}  ${t("DAYS")}`;
    }
    if (daysDiff === 1) {
      return `1  ${t("DAY")}`;
    }
    if (daysDiff === 0 && diffInMinutes(eventDate) > 2) {
      return ` ${t("TODAY")} - ${dateHHmm(eventDate)}`;
    }
  };
  const labelChip = labelChipParse();

  const syntheticStatus = !labelChip ? 3 : status;

  const quantityNumbers = userNumber - currentNumber;

  const parsStatusQueue = () => {
    if (quantityNumbers > 0) {
      return "YOUR_QUEUE_IN";
    }
    if (quantityNumbers === 0) {
      return "YOUR_TURN";
    }
    // status
    // if (quantityNumbers < 0) {
    //   return "SUCCESS";
    // }
  };

  const labelQueueParse = () => {
    if (quantityNumbers > 1) {
      return `${quantityNumbers} ${t("PERSONS")}`;
    }
    if (quantityNumbers === 1) {
      return `${quantityNumbers} ${t("PERSON")}`;
    }
  };

  const labelQueue = labelQueueParse();

  const isChip = isNotOwnerLive
    ? labelQueue
    : labelChip && status !== 3 && status !== 1;

  const isDisabledShare =
    !labelChip || status === 1 || status === 3 || status === 4;

  const isDisabledStop =
    !labelChip ||
    status === 1 ||
    status === 3 ||
    status === 4 ||
    userId !== owner;

  const isDisabledInfo = !labelChip || status === 3;

  const isInfoButton = !isLiveQueue;
  const isShareButton = !isNotOwnerLive;
  const isAddress = address && !isLiveQueue;

  return (
    <>
      <div
        className={cn(
          classes.row,
          isExpanded && classes.expanded,
          isExpandedProp && classes.rowToModal
        )}
      >
        <div className={classes.cell} onClick={handleToggleExpand}>
          {dateDDMMYYYY(eventDate)}
          <p className={cn(classes.timeCell, classes.secondaryTextCell)}>
            {dateHHmm(eventDate)}
          </p>
        </div>
        <div className={classes.cell} onClick={handleToggleExpand}>
          {name}
        </div>
        <div
          className={cn(
            classes.cell,
            classes.statusCell,
            syntheticStatus && classes[`status-${syntheticStatus}`]
            // status
            // "var(--success-green)"
          )}
          onClick={handleToggleExpand}
        >
          {isNotOwnerLive
            ? t(parsStatusQueue()) //status success?
            : t(parsStatus(syntheticStatus))}
          {isChip && (
            <div>
              <Chip
                label={isNotOwnerLive ? labelQueue : labelChip}
                size="small"
                color={
                  isNotOwnerLive ? "shief" : daysDiff > 1 ? "shief" : "danger"
                }
                sx={{
                  "&.MuiChip-root": {
                    borderRadius: "4px",
                    fontWeight: daysDiff > 1 ? "400" : "inherit",
                  },
                }}
              />
            </div>
          )}
        </div>
        {isExpanded && (
          <div
            className={cn(classes.expandedRow, isExpanded && classes.expanded)}
          >
            <div className={classes.messageCell}>{message}</div>
            {isAddress && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                className={classes.addressCell}
              >
                <div>
                  <p className={classes.secondaryTextCell}>{t("ADDRESS")}</p>
                  <p
                    className={classes.address}
                    onClick={() => handleOpenAddressMobile(address)}
                  >
                    {address}
                  </p>
                  <MapAddressShow address={address} />
                </div>
                {isMobile && (
                  <IconButton
                    onClick={() => handleOpenAddressMobile(address)}
                    color="luck"
                    sx={{
                      boxShadow: (theme) =>
                        theme.palette.buttonRoundMinishadow.main,
                    }}
                  >
                    <DirectionsIcon fontSize="large" />
                  </IconButton>
                )}
              </Stack>
            )}
            {isOwnerLive && (
              <OwnerBord
                dataParticipants={dataParticipants}
                invite={invite}
                finish={finish}
              />
            )}
            {isNotOwnerLive && (
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
                mb={2}
              >
                <QueueCounter
                  counterNumber={currentNumber}
                  backgroundColor={
                    quantityNumbers > 0
                      ? "var(--accent-red)"
                      : quantityNumbers === 0 && "var(--orange-secondary)"
                    // status
                    // : "var(--success-green)"
                  }
                  title={t("CURRENT_NUMBER")}
                />
                <QueueCounter
                  counterNumber={userNumber}
                  backgroundColor="var(--orange-secondary)"
                  title={t("MY_NUMBER")}
                />
              </Stack>
            )}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                type="button"
                onClick={() => setIsOpenModalDelete(true)}
                color="danger"
              >
                {t("DELETE")}
              </Button>

              <Button
                variant="outlined"
                startIcon={<StopCircleIcon />}
                type="button"
                onClick={() => setIsOpenModalStop(true)}
                disabled={isDisabledStop}
                color="danger"
              >
                {t("STOP")}
              </Button>

              {isShareButton && (
                <Button
                  variant="contained"
                  startIcon={<GroupAddIcon />}
                  type="button"
                  onClick={() => setIsOpenModalLink(true)}
                  disabled={isDisabledShare}
                  color="shief"
                  sx={{
                    ml: "auto",
                  }}
                >
                  {t("SHARE")}
                </Button>
              )}

              {isInfoButton &&
                (isLoadingInfo ? (
                  <CircularProgress size={35} color="shief" />
                ) : (
                  <IconButton
                    onClick={() => getParticipants(eventlink)}
                    disabled={isDisabledInfo}
                    color="shief"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <InfoIcon fontSize="large" />
                  </IconButton>
                ))}
            </div>
            <div
              className={cn(classes.createdDateCell, classes.secondaryTextCell)}
            >
              {t("CREATED_DATE")} {dateDDMMYYYY(createDate)}
            </div>
          </div>
        )}
      </div>
      {isOpenModalDelete && (
        <Modal
          id={_id}
          onSubmit={handleDel}
          open={isOpenModalDelete}
          closeModal={closeModalDelete}
          title={t("DELETE_EVENT")}
          text={t("WANT_DELETE_EVENT")}
        />
      )}

      {isOpenModalStop && (
        <Modal
          id={_id}
          onSubmit={handleStop}
          open={isOpenModalStop}
          closeModal={closeModalStop}
          title={t("STOP_EVENT")}
          text={t("WANT_STOP_EVENT")}
        />
      )}

      {isOpenModalLink && (
        <ModalLink
          id={_id}
          title={t("INV_EVENT")}
          keylink={linkInvitation}
          name={name}
          open={isOpenModalLink}
          closeModal={closeModalLink}
          isHotButton
          generateHotKey={generateHotKey}
          hotCode={hotCode}
          timeHotCode={timeHotCode}
        />
      )}

      {isOpenModalInviteParticipants && (
        <ModalParticipantsManager
          onSubmit={inviteSubmit}
          open={isOpenModalInviteParticipants}
          closeModal={closeModalInviteParticipants}
          title={`${t("INVITE_USER")} ${fullNameParticipants}`}
        />
      )}
      {isOpenModalFinishParticipants && (
        <ModalParticipantsManager
          onSubmit={finishSubmit}
          open={isOpenModalFinishParticipants}
          closeModal={closeModalFinishParticipants}
          title={`${t("END_MEETING")} ${fullNameParticipants}`}
        />
      )}
    </>
  );
};

export default TableRow;
