import React from "react";

import Fab from "@mui/material/Fab";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import classes from "./SaveButton.module.scss";

const SaveButton = ({ onClick = () => {} }) => {
  return (
    <div className={classes.button} onClick={onClick}>
      <Fab
        color="danger"
        sx={{
          width: "100px",
          height: "100px",
        }}
      >
        <SaveAsIcon sx={{ fontSize: "70px" }} />
      </Fab>
    </div>
  );
};

export default SaveButton;
