import React, { useState, useRef } from "react";

import { QRCodeSVG } from "qrcode.react";

import { ModalLink, ModalKey, ModalUseKey } from "components/Elements";
import { dateDDMMYYYY, diffInDays } from "utils/common";

import { IconButton, Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import { useTranslate } from "hooks/useTranslate.hook";

import cn from "classnames";

import classes from "./KeyCard.module.scss";

const KeyCard = ({
  data,
  fetchSaveEditKey,
  fetchDelKey,
  fetchAddEventForKey,
  userId,
}) => {
  const {
    _id,
    name,
    createDate,
    expiredDate,
    usageCount,
    message,
    keylink,
    owner,
  } = data;

  const [isOpenModalLink, setIsOpenModalLink] = useState(false);
  const [isOpenModalSetting, setIsOpenModalSetting] = useState(false);
  const [isOpenModalForm, setIsOpenModalForm] = useState(false);

  const { t } = useTranslate();

  const btnRef = useRef(null);

  // const linkDoverka = `${process.env.REACT_APP_URL}/useKey/${keylink}`;
  const linkDoverka = `/keys?useLink=${keylink}`;

  const handleClickLink = (e) => {
    if (e.target !== btnRef.current) {
      setIsOpenModalLink(true);
    }
  };

  const closeModalLink = () => {
    setIsOpenModalLink(false);
  };

  const handleClickSetting = (e) => {
    setIsOpenModalSetting(true);
  };

  const closeModalSetting = () => {
    setIsOpenModalSetting(false);
  };

  const handleClickForm = (e) => {
    setIsOpenModalForm(true);
  };

  const closeModalForm = () => {
    setIsOpenModalForm(false);
  };

  const incorrectExpiredDate = diffInDays(expiredDate) < 0;
  const incorrectUsageCount = usageCount === 0;
  const isDisabled = incorrectExpiredDate || incorrectUsageCount;

  const isFriendsKey = userId !== owner;

  return (
    <>
      <div className={cn(classes.wrap, isDisabled && classes.wrapOpacity)}>
        <div
          className={classes.front}
          onClick={isDisabled ? () => {} : handleClickLink}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "between",
              gap: "10px",
            }}
          >
            <div className={classes.info}>
              <p className={classes.name}>{name}</p>
              <p>Create Date: {dateDDMMYYYY(createDate)}</p>
              <p className={incorrectExpiredDate ? classes.incorrect : ""}>
                Expired Date: {dateDDMMYYYY(expiredDate)}
              </p>
              <p className={classes.message}>Description: {message}</p>
              <p
                className={cn(
                  classes.message,
                  incorrectUsageCount && classes.incorrect
                )}
              >
                Usage Count:{" "}
                <strong className={classes.count}>{usageCount}</strong>
              </p>
            </div>
            <div>
              <div>
                <QRCodeSVG
                  value={linkDoverka}
                  className={classes.qr}
                  size={110}
                  level={"L"}
                  includeMargin={false}
                />
              </div>
              {isFriendsKey && (
                <Button
                  ref={btnRef}
                  variant="contained"
                  type="button"
                  onClick={handleClickForm}
                  disabled={isDisabled}
                  color="shief"
                >
                  {t("USE_KEY")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <IconButton
          onClick={handleClickSetting}
          size="small"
          sx={{
            position: "absolute",
            top: "2px",
            right: "2px",
            backgroundColor: (theme) => theme.palette.shief.main,
            boxShadow: (theme) => theme.palette.buttonRoundMinishadow.main,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.dark.main,
            },
            "&:active": {
              backgroundColor: (theme) => theme.palette.dark.main,
            },
          }}
        >
          <SettingsIcon color="light" />
        </IconButton>
      </div>

      {isOpenModalLink && (
        <ModalLink
          keylink={linkDoverka}
          name={name}
          open={isOpenModalLink}
          closeModal={closeModalLink}
        />
      )}

      {isOpenModalSetting &&
        (isFriendsKey ? (
          <ModalKey
            open={isOpenModalSetting}
            closeModal={closeModalSetting}
            title={t("EDIT_KEY")}
            isDeleteButton
            onDelete={fetchDelKey}
            id={_id}
            isFriendsKey={isFriendsKey}
          />
        ) : (
          <ModalKey
            onSubmit={fetchSaveEditKey}
            open={isOpenModalSetting}
            closeModal={closeModalSetting}
            nameUser={name}
            expiredDateUser={expiredDate}
            usageCountUser={usageCount}
            messageUser={message}
            title={t("EDIT_KEY")}
            textButtonOk={t("SAVE")}
            isDeleteButton
            onDelete={fetchDelKey}
            id={_id}
          />
        ))}

      {isOpenModalForm && (
        <ModalUseKey
          onSubmit={fetchAddEventForKey}
          open={isOpenModalForm}
          closeModal={closeModalForm}
          title={t("CREATE_EVENT_FOR")}
          usedKey={keylink}
        />
      )}
    </>
  );
};

export default KeyCard;
