import { useState, useEffect } from "react";

import { Box, Paper, Stack, Typography } from "@mui/material";

import {
  SelectLanguage,
  BellButton,
  ModalList,
  Logo,
} from "components/Elements";

import { useStore, useTranslate } from "hooks";
import { initSocket, getSocket } from "utils/socket";

function Header({ changeLanguage, lng }) {
  const [isOpenModalBell, setIsOpenModalBell] = useState(false);
  const [listAlerts, setListAlerts] = useState([]);

  const { t } = useTranslate();

  const { token, setTrigerSocket } = useStore();

  const isAuthenticated = !!token;

  useEffect(() => {
    if (isAuthenticated) {
      initSocket();
      getSocket().on(`counters_profile_${token}`, (event) => {
        actionSocket(event);
      });
      getSocket().on("testEventResponse", (data) => {
        console.log("Response BACK:", data);
      });
    }
    // eslint-disable-next-line
  }, [isAuthenticated, token]);

  const actionSocket = (event) => {
    const { action, message } = event;

    setTrigerSocket(action);
    setListAlerts((listAlerts) => [...listAlerts, message]);

    switch (action) {
      case "DEL_EVENT":
        // тут зробимо логіку що відображати в колокольчику
        console.log(event?.message);
        break;

      default:
        break;
    }
  };

  const handleOpenBell = () => {
    setIsOpenModalBell(true);
  };
  const handleCloseBell = () => {
    setIsOpenModalBell(false);
  };

  //zagl
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userData"))?.userId;
    if (
      userId === "65cba3ae7b11e7219cacb9e0" ||
      userId === "65f764655159e3f4a594a52f"
    ) {
      setListAlerts([
        {
          id: 0,
          action: "",
          message: "Концерт Рамштайн",
          read: true,
          date: "2024-08-30T19:00:00.000Z",
        },
        {
          id: 1,
          action: "",
          message: "AOK",
          read: false,
          date: "2024-10-01T12:00:00.000Z",
        },
        {
          id: 2,
          action: "",
          message: "CNC industrial club 2024",
          read: false,
          date: "2024-11-05T10:00:00.000Z",
        },
      ]);
    }
  }, []);

  //zagl
  const handleDelAlert = (id) => {
    const newList = [...listAlerts].filter((item) => item.id !== id);
    setListAlerts(newList);
  };

  //zagl
  const clearAllAlerts = () => {
    setListAlerts([]);
  };

  //zagl
  const calcCounter = () =>
    [...listAlerts].reduce((acc, item) => acc + Number(!item.read), 0);

  return (
    <>
      <Box
        sx={{
          flex: "0 0 auto",
          zIndex: "10",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "56px",
        }}
      >
        <Paper elevation={3}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="var(--white)"
            p="12px 12px"
          >
            <Logo height="32" />
            <Typography
              variant="h5"
              component="h2"
              color="headerColor"
              sx={{ flexGrow: 1 }}
            >
              {t("MAIN_HEADER")}
            </Typography>

            <SelectLanguage changeLanguage={changeLanguage} lng={lng} />

            {/* !!!! block of Bell: "false" */}
            {false && (
              <BellButton
                data={listAlerts}
                counter={calcCounter()}
                handleBellButton={handleOpenBell}
              />
            )}
          </Stack>
        </Paper>
      </Box>
      {isOpenModalBell && (
        <ModalList
          clearAllAlerts={clearAllAlerts}
          handleDelAlert={handleDelAlert}
          open={isOpenModalBell}
          closeModal={handleCloseBell}
          listAlerts={listAlerts}
        />
      )}
    </>
  );
}

export default Header;
