import { useTranslate, useConfirmPage } from "hooks";

import { CircularProgress, Typography, Stack, Button } from "@mui/material";

export default function ConfirmPage() {
  const { t } = useTranslate();

  const { isLoading, isRes, error, handleContinue } = useConfirmPage();

  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      {isLoading && <CircularProgress color="shief" />}
      {isRes && (
        <Typography variant="h4" component="p" color="dark" align="center">
          {t("CONG_EMAIL_CONFIRMED")}
        </Typography>
      )}
      {error && (
        <>
          <Typography
            mt="auto"
            mb="auto"
            variant="h4"
            component="p"
            color="dark"
            align="center"
          >
            {t("CONFIRM_LINK_ERROR")}
          </Typography>
          <Button
            variant="contained"
            type="button"
            size="large"
            color="shief"
            sx={{
              flex: "0 0 auto",
            }}
            onClick={handleContinue}
          >
            {t("CONTINUE")}
          </Button>
        </>
      )}
    </Stack>
  );
}
