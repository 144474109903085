import { Tabs, Tab } from "@mui/material";

import { IconMuiDraw } from "components/Elements";

import classes from "./TabsModeView.module.scss";

const TabsModeView = ({ viewModeConfig, valueTab, handleTab }) => {
  return (
    <Tabs
      value={valueTab}
      onChange={handleTab}
      TabIndicatorProps={{
        sx: {
          display: "none",
        },
      }}
      sx={{
        overflow: "visible",
        "& .MuiTabs-scroller": {
          overflow: "visible !important",
        },
        ".MuiTabs-flexContainer": {
          gap: "10px",
        },
      }}
    >
      {viewModeConfig.map(({ value, icon }) => (
        <Tab
          key={value}
          value={value}
          icon={
            <IconMuiDraw
              name={icon}
              sx={{
                fontSize: valueTab === value ? "33px" : "35px",
              }}
              className={classes.effect}
            />
          }
          sx={{
            padding: "8px",
            minWidth: "51px",
            borderRadius: "50%",
            color: (theme) => theme.palette.gray.darkMiddle,
            boxShadow: (theme) =>
              valueTab === value
                ? "none"
                : theme.palette.buttonRoundMinishadow.main,
            "&.Mui-selected": {
              color: (theme) => theme.palette.shief.main,
            },
          }}
          className={classes.effect}
        ></Tab>
      ))}
    </Tabs>
  );
};

export default TabsModeView;
