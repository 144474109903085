import { useState, useEffect, useContext } from "react";

import { ContextStore } from "context/ContextStore";

import Resizer from "react-image-file-resizer";

import { useHttp } from "hooks";

export const useMenuPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState({
    email: "",
    fullName: "",
    emailIsConfirm: false,
    avatar: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);

  const { request } = useHttp();

  const { token, notif, logout, openModalTutorial } = useContext(ContextStore);

  useEffect(() => {
    getProfileInfo();
    // eslint-disable-next-line
  }, []);

  const openModalSupport = () => {
    setIsOpenModalSupport(true);
  };

  const closeModalSupport = () => {
    setIsOpenModalSupport(false);
  };

  const getProfileInfo = async () => {
    setIsLoading(true);
    try {
      const res = await request(`/api/auth/getUserInfo`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setInfo(res);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfileInfo = async () => {
    setIsLoading(true);
    try {
      await request(
        `/api/auth/updateUserInfo`,
        "PUT",
        {
          ...info,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setIsEdit(false);
    } catch (e) {
    } finally {
      getProfileInfo();
      setIsLoading(false);
    }
  };

  const confirm = async () => {
    try {
      const { message } = await request("/api/auth/createConfirmLink", "POST", {
        email: info.email,
      });
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleField = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
    !isEdit && setIsEdit(true);
  };

  const chooseImgHandler = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      70, // якість зображення
      0,
      (uri) => {
        if (
          JSON.stringify(uri) !==
          `"data:application/octet-stream;base64,dW5kZWZpbmVk"`
        ) {
          setInfo({ ...info, avatar: uri });
          setIsEdit(true);
          // console.log(JSON.stringify(uri));
        }
      },
      "base64"
    );
  };

  const removeImgHandler = () => {
    setInfo({ ...info, avatar: "" });
    !isEdit && setIsEdit(true);
  };

  const handleSupportReport = async (supportData) => {
    try {
      const { message } = await request(
        "/api/auth/supportReport",
        "POST",
        supportData,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      notif(message, "alert");
    } catch (e) {}
  };

  return {
    isLoading,
    info,
    isEdit,
    isOpenModalSupport,
    openModalSupport,
    closeModalSupport,
    updateProfileInfo,
    confirm,
    handleField,
    chooseImgHandler,
    removeImgHandler,
    logout,
    handleSupportReport,
    openModalTutorial,
  };
};
