import { Stack, Typography } from "@mui/material";

import ChipQueueCounter from "./ChipQueueCounter";

const QueueCounter = ({ counterNumber, backgroundColor, title }) => {
  const digits = counterNumber ? counterNumber.toString().split("") : [];
  return (
    <div>
      <Typography
        variant="h6"
        sx={{ color: backgroundColor }}
        mb={1}
        align="center"
      >
        {title}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {digits.map((item, index) => (
          <ChipQueueCounter
            key={index}
            number={item}
            backgroundColor={backgroundColor}
          />
        ))}
      </Stack>
    </div>
  );
};

export default QueueCounter;
