import { useState, useEffect, useContext } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import { ContextStore } from "context/ContextStore";

import { useHttp } from "hooks/useHttp.hook";

export const useConfirmPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRes, setIsRes] = useState(false);
  const [error, setError] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { request } = useHttp();

  const { notif } = useContext(ContextStore);

  const confirmKey = searchParams.get("confirmKey") ?? "";

  useEffect(() => {
    confirm();
    // eslint-disable-next-line
  }, []);

  const confirm = async () => {
    try {
      const res = await request(
        `/api/auth/confirm?confirmKey=${confirmKey}`,
        "GET",
        null
      );
      setIsLoading(false);
      setIsRes(true);
      notif(res.message, "alert");
    } catch (e) {
      setIsLoading(false);
      setError(e);
      notif(e.message, "error");
    }
  };

  const handleContinue = () => {
    navigate("/");
  };

  return { isLoading, isRes, error, handleContinue };
};
