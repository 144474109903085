import { Button, Typography } from "@mui/material";

import { AvatarImg } from "components/Elements";

import { useTranslate } from "hooks";

import classes from "./OwnerBord.module.scss";

const OwnerBord = ({ dataParticipants, invite, finish }) => {
  const { t } = useTranslate();

  return (
    <ul className={classes.listBord}>
      {dataParticipants.map(({ id, fullName, foto }, index) => (
        <li key={id} className={classes.itemBord}>
          <Typography variant="body1">{index + 1}</Typography>

          <AvatarImg fullName={fullName} foto={foto} />
          <Typography variant="body1" className={classes.name}>
            {fullName}
          </Typography>

          <div className={classes.btnBoard}>
            <Button
              variant="contained"
              type="button"
              onClick={() => invite(id, fullName)}
              color="shief"
              sx={{
                marginRight: "6px",
              }}
            >
              {t("INVITE")}
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => finish(id, fullName)}
              color="danger"
            >
              {t("FINISH")}
            </Button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default OwnerBord;
