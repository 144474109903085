import { useState } from "react";

import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordField = ({ label, value, onChange, name }) => {
  const [showPass, setShowPass] = useState(false);

  const handleClickShowPass = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      type={showPass ? "text" : "password"}
      value={value}
      name={name}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPass}>
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
