import { FormControlLabel, Switch } from "@mui/material";

const Toggle = ({ label, checked, handleSwitch, sx }) => {
  return (
    <FormControlLabel
      control={
        <Switch checked={checked} onChange={handleSwitch} color="shief" />
      }
      label={label}
      sx={sx}
    />
  );
};

export default Toggle;
