import { Stack } from "@mui/material";

import { viewModeConfig } from "config/configs";

import cn from "classnames";

import {
  useTranslate,
  useMessagesPage,
  useGetInfoInvite,
  useGetParticipants,
} from "hooks";

import {
  ModalForm,
  TableRow,
  Modal,
  BodyModalInvite,
  ModalAlert,
  BodyModalParticipants,
  ModalQrReader,
  ModalUseHot,
  TabsModeView,
  EventsCalendar,
  ModalCalendarEvent,
  SpeedDialMenu,
  Toggle,
} from "components/Elements";

import classes from "./MessagesPage.module.scss";

const MessagesPage = () => {
  const { t } = useTranslate();

  const {
    filteredEvents,
    isOpenModalCreate,
    isOpenModalQrReader,
    isOpenModalHot,
    userId,
    closeModalCreate,
    handleAddButton,
    closeModalQrReader,
    handleQrButton,
    closeModalHot,
    handleHotButton,
    handleSubmit,
    handleDel,
    handleStop,
    getDataTable,
    checkedPending,
    handleSwitch,
    hotCode,
    setHotCode,
    generateHotKey,
    timeHotCode,
    timerId,
    useHotKey,
    handleAddMenu,
    pageScrollDown,
    handleModeView,
    viewMode,
    isOpenModalCalendarEvent,
    closeModalCalendarEvent,
    onClickCalendarEvent,
    currentCalendarEventId,
    checkedLiveOueue,
    handleSwitchLiveOueue,
    isLiveToggle,
  } = useMessagesPage();

  const {
    link,
    isOpenModalInvite,
    closeModalInvite,
    joinEvent,
    removeParamUseKey,
    inviteInfo,
  } = useGetInfoInvite(getDataTable);

  const {
    participants,
    isOpenModalParticipants,
    getParticipants,
    closeModalParticipants,
    isLoadingInfo,
  } = useGetParticipants();

  return (
    <div
      className={cn(
        classes.messagePage,
        viewMode === "calendar" && classes.messagePageCalendar
      )}
      onClick={handleAddMenu}
    >
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Toggle
          label={t("ONLY_PENDING")}
          checked={checkedPending}
          handleSwitch={handleSwitch}
        />

        <TabsModeView
          viewModeConfig={viewModeConfig}
          valueTab={viewMode}
          handleTab={handleModeView}
        />
      </Stack>
      {isLiveToggle && (
        <Toggle
          label={t("ONLY_LIVE_QUEUE")}
          checked={checkedLiveOueue}
          handleSwitch={handleSwitchLiveOueue}
          sx={{ marginBottom: "16px" }}
        />
      )}

      {viewMode === "table" && (
        <div className={classes.table}>
          <div className={cn(classes.row, classes.header)}>
            <div className={classes.cell}>{t("EVENT_DATE")}</div>
            <div className={classes.cell}>{t("NAME")}</div>
            <div className={cn(classes.cell, classes.statusCell)}>
              {t("STATUS")}
            </div>
          </div>
          {filteredEvents.map((item, index) => (
            <TableRow
              key={item._id}
              {...item}
              handleDel={handleDel}
              handleStop={handleStop}
              userId={userId}
              getParticipants={getParticipants}
              hotCode={hotCode}
              generateHotKey={generateHotKey}
              timeHotCode={timeHotCode}
              setHotCode={setHotCode}
              timerId={timerId}
              tableScroll={
                index === filteredEvents.length - 1 && pageScrollDown
              }
              isLoadingInfo={isLoadingInfo}
            />
          ))}
        </div>
      )}
      {viewMode === "calendar" && (
        <div className={classes.calendar}>
          <EventsCalendar
            events={filteredEvents}
            onClickCalendarEvent={onClickCalendarEvent}
          />
        </div>
      )}
      <SpeedDialMenu
        handleQrButton={handleQrButton}
        handleAddButton={handleAddButton}
        handleHotButton={handleHotButton}
      />
      {isOpenModalCreate && (
        <ModalForm
          onSubmit={handleSubmit}
          open={isOpenModalCreate}
          closeModal={closeModalCreate}
          title={t("CREATE_EVENT")}
        />
      )}
      {isOpenModalInvite && (
        <Modal
          id={link}
          onSubmit={joinEvent}
          open={isOpenModalInvite}
          closeModal={closeModalInvite}
          title={t("INVITATION")}
          text={<BodyModalInvite inviteInfo={inviteInfo} />}
          textButtonOk={t("ACCEPT")}
          additionalFuncCancel={removeParamUseKey}
        />
      )}
      {isOpenModalParticipants && (
        <ModalAlert
          open={isOpenModalParticipants}
          closeModal={closeModalParticipants}
          title={t("PARTICIPANS")}
          text={<BodyModalParticipants participantsInfo={participants} />}
        />
      )}
      {isOpenModalQrReader && (
        <ModalQrReader
          open={isOpenModalQrReader}
          closeModal={closeModalQrReader}
        />
      )}
      {isOpenModalHot && (
        <ModalUseHot
          onSubmit={useHotKey}
          open={isOpenModalHot}
          closeModal={closeModalHot}
          title={t("INSERT_HOT_KEY")}
          textButtonOk={t("USE_HOT_KEY")}
        />
      )}
      {isOpenModalCalendarEvent && (
        <ModalCalendarEvent
          open={isOpenModalCalendarEvent}
          closeModal={closeModalCalendarEvent}
          body={
            <TableRow
              isExpandedProp={true}
              {...filteredEvents.find(
                (item) => item._id === currentCalendarEventId
              )}
              handleDel={handleDel}
              handleStop={handleStop}
              userId={userId}
              getParticipants={getParticipants}
              hotCode={hotCode}
              generateHotKey={generateHotKey}
              timeHotCode={timeHotCode}
              setHotCode={setHotCode}
              timerId={timerId}
            />
          }
        />
      )}
    </div>
  );
};

export default MessagesPage;
