import { createTheme } from "@mui/material";

const colors = {
  white: "#ffffff",
  black: "#000000",
  accentOrange: "#ff6600",
  orangeSecondary: "#f38840",
  accentRed: "#f91f2b",
  error: "red",
  successGreen: "#4caf50",
  gray: "#808080",
  grayMiddle: "rgb(235, 228, 228)",
  grayDarkmiddle: "rgb(158, 158, 158)",
  neutralGray: "rgb(192, 192, 192)",
  neutralGray2: "rgb(176, 190, 197)",
  grayLight: "#f4f4f4",
  buttonRoundMinishadow:
    "0px 4px 8px -1px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 18px 1px rgba(0, 0, 0, 0.12)",
};

export const theme = createTheme({
  palette: {
    shief: {
      main: colors.accentOrange,
      contrastText: colors.white,
      dark: colors.black,
      light: colors.orangeSecondary,
    },
    danger: {
      main: colors.accentRed,
      contrastText: colors.white,
      dark: colors.black,
    },
    luck: {
      main: colors.successGreen,
      contrastText: colors.white,
      dark: colors.black,
    },
    light: {
      main: colors.white,
    },
    dark: {
      main: colors.black,
      contrastText: colors.white,
      dark: colors.black,
    },
    gray: {
      main: colors.gray,
      darkMiddle: colors.grayDarkmiddle,
      neutral: colors.neutralGray,
      middle: colors.grayMiddle,
    },
    modalButton: {
      main: colors.black,
    },
    pickerAccent: {
      main: colors.accentOrange,
    },
    speedRoundButton: {
      main: colors.black,
      contrastText: colors.white,
    },
    buttonRoundMinishadow: {
      main: colors.buttonRoundMinishadow,
    },
    headerColor: {
      main: colors.black,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-containedShief ": {
            "&:hover": {
              backgroundColor: colors.black,
            },
            "&:active": {
              backgroundColor: colors.black,
            },
          },
          "&.MuiButton-outlinedDanger": {
            "&:hover": {
              color: colors.white,
              backgroundColor: colors.accentRed,
            },
            "&:active": {
              color: colors.white,
              backgroundColor: colors.accentRed,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-colorShief": {
            "&:hover": {
              color: colors.black,
            },
            "&:active": {
              color: colors.black,
            },
          },
          "&.MuiIconButton-colorLuck ": {
            "&:hover": {
              color: colors.black,
            },
            "&:active": {
              color: colors.black,
            },
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          "&.MuiFab-danger": {
            "&:hover": {
              backgroundColor: colors.black,
            },
            "&:active": {
              backgroundColor: colors.black,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: colors.black,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: colors.black,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: colors.black,
          },
          "&.MuiInput-underline:after": {
            borderBottomColor: colors.black,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: colors.black,
          },
        },
      },
    },
  },
});
