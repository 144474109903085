import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

export default function ModalField({
  onSubmit,
  open,
  closeModal,
  title = "",
  text = "",
  textButtonOk,
  textButtonCancel,
}) {
  const [email, setEmail] = useState("");
  const { t } = useTranslate();

  const handleCancel = () => {
    closeModal();
  };

  const handleOk = () => {
    onSubmit(email);
    closeModal();
  };

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <TextField
            fullWidth
            id="standard-basic"
            label={t("EMAIL")}
            variant="standard"
            type="email"
            name="email"
            onChange={changeHandler}
            value={email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="modalButton">
            {textButtonCancel || t("CANCEL")}
          </Button>
          <Button onClick={handleOk} autoFocus color="modalButton">
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
