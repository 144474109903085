import {
  Stack,
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  ModalAlert,
  SelectLanguage,
  ModalField,
  Logo,
  PasswordField,
  Copyright,
} from "components/Elements";

import { useTranslate, useLoginPage } from "hooks";

import classes from "./LoginPage.module.scss";

const AuthPage = () => {
  const { t } = useTranslate();

  const {
    activeTab,
    form,
    modalTitle,
    handleTabChange,
    handleSubmit,
    changeHandler,
    isOpenModalForgot,
    setIsOpenModalForgot,
    closeModalForgot,
    isOpenModal,
    closeModal,
    sendResetPassLink,
    loading,
    lng,
    changeLanguage,
    isOpenModalPolicy,
    setIsOpenModalPolicy,
    closeModalPolicy,
  } = useLoginPage();

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        backgroundColor="var(--background-light)"
        height="100%"
      >
        <Box backgroundColor="var(--white)" className={classes.authBox}>
          <div className={classes.selectLanguage}>
            <SelectLanguage lng={lng} changeLanguage={changeLanguage} />
          </div>

          <Box mb="20px">
            <Logo height="60" />
          </Box>

          <Box mb="10px">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: (theme) => theme.palette.shief.main,
                },
              }}
            >
              <Tab
                label={t("LOGIN")}
                value="login"
                sx={{
                  "&.Mui-selected": {
                    color: (theme) => theme.palette.shief.main,
                  },
                }}
              />
              <Tab
                label={t("REGISTER")}
                value="register"
                sx={{
                  "&.Mui-selected": {
                    color: (theme) => theme.palette.shief.main,
                  },
                }}
              />
            </Tabs>
          </Box>
          <Stack
            component="form"
            spacing={2}
            alignItems="center"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              label={t("EMAIL")}
              variant="standard"
              type="email"
              name="email"
              onChange={changeHandler}
              value={form.email}
            />
            {activeTab === "register" && (
              <TextField
                fullWidth
                label={t("FULL_NAME")}
                variant="standard"
                type="text"
                name="fullName"
                onChange={changeHandler}
                value={form.fullName}
              />
            )}
            <PasswordField
              label={t("PASSWORD")}
              name="password"
              value={form.password}
              onChange={changeHandler}
            />
            {activeTab === "register" && (
              <PasswordField
                label={t("CONFIRM_PASSWORD")}
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={changeHandler}
              />
            )}
            {activeTab === "login" && (
              <Button
                variant="text"
                color="dark"
                sx={{ textTransform: "none" }}
                onClick={() => setIsOpenModalForgot(true)}
              >
                {t("FORGOT_PASS")}
              </Button>
            )}

            {activeTab === "register" && (
              <div>
                <FormControlLabel
                  required
                  control={<Checkbox color="shief" />}
                  label={t("POLICY")}
                  sx={{ marginRight: 0 }}
                />
                <br />
                <Button
                  variant="text"
                  color="dark"
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={() => setIsOpenModalPolicy(true)}
                >
                  {t("MORE")}
                </Button>
              </div>
            )}

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              size="large"
              color="shief"
            >
              {t(activeTab === "login" ? "LOGIN" : "REGISTER")}
            </Button>
          </Stack>
        </Box>
        <Box className={classes.copyright}>
          <Copyright />
        </Box>
      </Stack>

      {isOpenModal && (
        <ModalAlert
          open={isOpenModal}
          closeModal={closeModal}
          title={t(modalTitle)}
        />
      )}

      {isOpenModalForgot && (
        <ModalField
          onSubmit={sendResetPassLink}
          open={isOpenModalForgot}
          closeModal={closeModalForgot}
          title={t("ENTER_MAIL")}
          textButtonOk={t("SEND_LINK")}
        />
      )}

      {isOpenModalPolicy && (
        <ModalAlert
          open={isOpenModalPolicy}
          closeModal={closeModalPolicy}
          title={t("TITLE_POLICY")}
          text={t("TEXT_POLICY")}
        />
      )}
    </>
  );
};

export default AuthPage;
