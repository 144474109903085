import { useState } from "react";

import { SpeedDial, SpeedDialAction } from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import AddIcon from "@mui/icons-material/Add";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SpeedDialMenu = ({
  handleQrButton,
  handleAddButton,
  handleHotButton,
  isHot = true,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const allActions = [
    { icon: <WhatshotIcon />, onClick: handleHotButton, show: isHot },
    { icon: <AddIcon />, onClick: handleAddButton, show: true },
    { icon: <QrCode2Icon />, onClick: handleQrButton, show: true },
  ];

  const actions = allActions.filter((action) => action.show);

  return (
    <SpeedDial
      ariaLabel="SpeedDial"
      icon={
        <SpeedDialIcon
          icon={<ExpandLessIcon />}
          openIcon={<ExpandMoreIcon />}
        />
      }
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      sx={{
        position: "fixed",
        bottom: 80,
        right: 20,
        zIndex: 1050,
        ".MuiSpeedDial-fab": {
          backgroundColor: (theme) => theme.palette.speedRoundButton.main,
        },
        ".MuiSpeedDial-fab:hover, .MuiSpeedDial-fab:active, .MuiSpeedDial-fab:focus":
          {
            backgroundColor: (theme) => theme.palette.speedRoundButton.main,
          },
        ".MuiSpeedDialAction-fab": {
          backgroundColor: (theme) => theme.palette.speedRoundButton.main,
          color: (theme) => theme.palette.speedRoundButton.contrastText,
        },
        ".MuiSpeedDialAction-fab:hover, .MuiSpeedDialAction-fab:active, .MuiSpeedDialAction-fab:focus":
          {
            backgroundColor: (theme) => theme.palette.speedRoundButton.main,
            color: (theme) => theme.palette.speedRoundButton.contrastText,
          },
      }}
    >
      {actions.map((action, index) => (
        <SpeedDialAction
          key={index}
          icon={action.icon}
          onClick={() => {
            action.onClick();
            handleClose();
          }}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialMenu;
