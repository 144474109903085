import { useState } from "react";

export const useOwnerBord = () => {
  const [dataParticipants, setDataParticipants] = useState([
    {
      id: "1",
      fullName: "name1",
      number: 1,
      foto: "",
      isFinish: false,
      isInvite: false,
    },
    {
      id: "2",
      fullName: "name dlfldskfl kfdsmfkls",
      number: 1,
      foto: "",
      isFinish: false,
      isInvite: false,
    },
    {
      id: "3",
      fullName: "name15555555555555",
      number: 1,
      foto: "",
      isFinish: false,
      isInvite: false,
    },
  ]);
  const [idParticipants, setIdParticipants] = useState(null);
  const [fullNameParticipants, setfullNameParticipants] = useState(null);
  const [isOpenModalInviteParticipants, setIsOpenModalInviteParticipants] =
    useState(false);

  const [isOpenModalFinishParticipants, setIsOpenModalFinishParticipants] =
    useState(false);

  const invite = (id, fullName) => {
    setIdParticipants(id);
    setfullNameParticipants(fullName);
    setIsOpenModalInviteParticipants(true);
  };

  const finish = (id, fullName) => {
    setIdParticipants(id);
    setfullNameParticipants(fullName);
    setIsOpenModalFinishParticipants(true);
  };

  const closeModalInviteParticipants = () => {
    setIsOpenModalInviteParticipants(false);
  };

  const closeModalFinishParticipants = () => {
    setIsOpenModalFinishParticipants(false);
  };

  const inviteSubmit = () => {
    console.log(idParticipants);
  };

  const finishSubmit = () => {
    console.log(idParticipants);
  };

  return {
    dataParticipants,
    fullNameParticipants,
    invite,
    finish,
    setDataParticipants, //видалити
    isOpenModalInviteParticipants,
    closeModalInviteParticipants,
    isOpenModalFinishParticipants,
    closeModalFinishParticipants,
    inviteSubmit,
    finishSubmit,
  };
};
