import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  TextField,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

const ModalSupport = ({
  onSubmit,
  open,
  closeModal,
  title,
  text,
  fullNameInfo = "",
  emailInfo = "",
}) => {
  const [email, setEmail] = useState(emailInfo);
  const [name, setName] = useState(fullNameInfo);
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email || !name || !message) {
      setValidationErrors({
        email: !email,
        name: !name,
        message: !message,
      });
      return;
    }

    setValidationErrors({});

    onSubmit({ email, name, message });

    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{title || t("SUPPORT_TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text || t("DESCRIBE_PROBLEM")}</DialogContentText>
        <TextField
          fullWidth
          label={t("EMAIL")}
          variant="standard"
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          error={validationErrors.email}
          helperText={validationErrors.email && t("ERROR_EMAIL_REQ")}
          sx={{
            mt: "16px",
            ".MuiFormHelperText-root": {
              color: (theme) => theme.palette.luck.main,
            },
          }}
        />
        <TextField
          fullWidth
          label={t("NAME")}
          variant="standard"
          type="text"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          error={validationErrors.name}
          helperText={validationErrors.name && t("ERROR_NAME_REQ")}
          sx={{
            mt: "16px",
          }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          label={t("MESSAGE_SUPPORT")}
          variant="standard"
          type="text"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          error={validationErrors.message}
          helperText={validationErrors.message && t("ERROR_MESSAGE_REQ")}
          inputProps={{
            maxLength: 1000,
          }}
          sx={{
            mt: "16px",
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="modalButton">
          {t("CANCEL")}
        </Button>
        <Button type="submit" color="modalButton">
          {t("SEND")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSupport;
