import FlipNumbers from "react-flip-numbers";

import { Chip } from "@mui/material";

const ChipQueueCounter = ({ number, backgroundColor }) => {
  return (
    <Chip
      sx={{
        height: "auto",
        width: "auto",
        paddingTop: "20px",
        paddingBottom: "16px",
        color: (theme) => theme.palette.light.main,
        backgroundColor: backgroundColor,
        marginRight: "8px",
        "& .MuiChip-label": {
          paddingLeft: "4px",
          paddingRight: "4px",
        },
      }}
      label={
        <FlipNumbers
          height={40}
          width={40}
          play
          perspective={100}
          numbers={`${number}`}
          numberStyle={{ fontSize: "14px" }}
        />
      }
    />
  );
};

export default ChipQueueCounter;
